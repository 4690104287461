// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";
$enable-negative-margins: true !default;
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
    color: #00a651 !important;
    font-weight: bold;
}

.nav-link:hover,
.nav-link:focus {
    color: #00a651 !important;
}

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";

$custom-colors: (
    "theme": #00a651,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$font-family-sans-serif: "Poppins", sans-serif;

@import "~bootstrap/scss/bootstrap";
@import "~swiper/scss";
@import "~bootstrap-icons/font/bootstrap-icons";
